import { connect } from 'react-redux';
import React from 'react';
// When a user login is successfull, the user is redirected to this page.
// When this page renders, it will check if the user is already logged in and update the redux state.
// If the user is not logged in, it will redirect the user to the login page.

import { loggedInCheck } from '../slices/userSlice';
import { Redirect } from 'react-router';

// If the user is already logged in, it will redirect the user to the dashboard.
class AuthCheck extends React.Component {

  componentDidMount() {
    this.props.loggedInCheck();
  }

  render() {
    if (! this.props.user.loginPending) {
      if (this.props.user.loginError) return <Redirect to="/login" />;

      if (this.props.checkAdmin === 'true') {
        if (this.props.user.authenticated && this.props.user.role === 'admin') {
          return this.props.children;
        }
      } else {
        if (this.props.user.authenticated) {
          return this.props.children;
        }
      }
    }

    // Display nothing.
    return <div></div>;
  }
}

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => {
  return {
    loggedInCheck: () => dispatch(loggedInCheck())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthCheck);
