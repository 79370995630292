import { Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';

const StageOne = (props) => {
    const handleSubmit = (values) => {
        props.handleNext(values)
    };

    return (
        <Formik validationSchema={Yup.object({
            tradingName: Yup.string().required("Trading Name is required."),
            abn: Yup.string().required("ABN is required.").matches(/^[0-9]+$/, "Please enter a valid ABN.").min(11, "Please enter a valid ABN.").max(11, "Please enter a valid ABN."),
        })} initialValues={props.formData} onSubmit={handleSubmit}>
            <Form>
                <div className="step">
                    <h2>Supplier Details</h2>
                    <div className="stepform">
                        <label>Trading Name</label>
                        <Field type="text" name="tradingName"/>
                        <ErrorMessage component="div" name="tradingName" className="errormsg"/>
                        <label>ABN</label>
                        <Field type="number" name="abn"/>
                        <ErrorMessage component="div" name="abn" className="errormsg"/>
                        <p>Please note, we find your Company Name based off the provided ABN.</p>
                    </div>
                    <button className="stepbutton" type="submit">Next</button>
                </div>
            </Form>
        </Formik>
    )
}

export default StageOne;
