import React, { Component } from "react";
import './Admin.scss'
import { fetchSubmissions, deleteSubmission, generateToken } from '../api/adminAPI'
import { Table, Space, Tag, Input, Modal, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';

class Admin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submissions: [],
      searchValue: '',
      isDeletePopupOpen: false,
      isSendTokenPopupOpen: false,
      deleteid: '',
      generatedToken: '',
      clientEmail: ''
    }
    this.changeSearchValue = this.changeSearchValue.bind(this);
    this.deleteSubmission = this.deleteSubmission.bind(this);
    this.toggleDeletePopup = this.toggleDeletePopup.bind(this);
    this.toggleTokenPopup = this.toggleTokenPopup.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    fetchSubmissions()
      .then(data => {
        this.setState({ ...this.state, submissions: data || [] });
      }).catch((err) => console.log(err));
  }

  /**
   * Update the search value fo looking through the submission
   * @param {DOMEvent} e The on change event
   */
  changeSearchValue(e) {
    this.setState({ ...this.state, searchValue: e.target.value });
  }

  /**
   * Delete a submission
   * 
   * @param {string} id The id of the submission to delete
   */
  deleteSubmission(id) {
    deleteSubmission(id)
      .then(data => {
        if (data.success) {
          this.setState({
            ...this.state,
            submissions: this.state.submissions.filter(submission => submission.id !== id),
            deleteid: '',
            isDeletePopupOpen: false
          });
        }
      }).catch((err) => console.log(err));
  }

  /**
   * Toggle the delete confirmation modal state
   */
  toggleDeletePopup() {
    this.setState({ ...this.state, isDeletePopupOpen: !this.state.isDeletePopupOpen, deleteid: '' });
  }

  /**
   * Toggle the token generation model state
   */
  toggleTokenPopup() {
    this.setState({ ...this.state, isSendTokenPopupOpen: !this.state.isSendTokenPopupOpen });
  }

  /**
   * Handle an on change event for field input
   * 
   * @param {DOMEvent} e The onchange event of a field
   */
  handleChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  }

  /**
   * Send a token generation request and show the user the resulting token
   */
  generateToken() {
    generateToken(this.state.clientEmail)
      .then(data => {
        if (data.success) {
          this.setState({ ...this.state, generatedToken: data.msg.token });
        }
      });
  }

  /**
   * Copy the token or token url to the users clipboard
   * 
   * @param {string} token The token or token url to be copied
   */
  copyToken(token) {
    navigator.clipboard.writeText(token);
  }

  render() {
    // Filter our original array with the submission data and use this as our data source for the table as it filters by Company Name.
    // We are not changing the original array so we can use it to search within the array.
    const filteredData = this.state.submissions.filter(entry => {
      return entry.companyName.toLowerCase().includes(this.state.searchValue.toLowerCase());
    });

    const columns = [
      {
        title: 'Submission ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => (
          <Tag color="green">
            {text}
          </Tag>
        ),
        filters: [
          { text: 'Pending ICM Approval', value: 'PENDING_ICM_APPROVAL' },
          { text: 'Awaiting Correction', value: 'AWAITING_CORRECTION' },
          { text: 'Approved', value: 'APPROVED' },
          { text: 'Rejected', value: 'REJECTED' },

        ],
        onFilter: (value, record) => {
          return record.status === value;
        },
      },
      {
        title: 'Company Name',
        dataIndex: 'companyName',
        key: 'companyName',
      },
      {
        title: 'Last Updated Date & Time',
        key: 'tags',
        dataIndex: 'updatedAt',
        render: (text) => (
          <Space size="middle">
            {new Date(text).toLocaleDateString(
              'en-gb',
            )}
            {new Date(text).toLocaleTimeString()}
          </Space>
        ),
        sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => (
          <div>
            <Space size="small">
              <Link className="viewbutton" to={`/admin/submission/${record.id}`}>View Submission</Link>
              {record.status === "PENDING_ICM_APPROVAL" ? <button className="deleteButton" onClick={() => this.setState({ ...this.state, isDeletePopupOpen: !this.state.isDeletePopupOpen, deleteid: record.id })}>Delete Submission</button> : null}
            </Space>
          </div>

        ),
        width: "15%"
      },
    ];

    return (
      <div className="submissiontable">
        <Modal
          title="Delete Submission"
          visible={this.state.isDeletePopupOpen}
          onOk={() => this.deleteSubmission(this.state.deleteid)}
          onCancel={this.toggleDeletePopup}
        >
          <h3 style={{ color: "red" }}>Are you sure you want to delete this submission?</h3>
        </Modal>
        <Modal
          title="Send Token"
          visible={this.state.isSendTokenPopupOpen}
          footer={this.state.generatedToken == '' ?
            [
              <Button key="back" onClick={this.toggleTokenPopup}>
                Return
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => this.generateToken()}
              >
                Generate
              </Button>,
            ] : [
              <Button key="back" onClick={this.toggleTokenPopup}>
                Return
              </Button>
            ]
          }
        >
          {this.state.generatedToken == '' ?
            <>
              <p>(Optional) Provide an email to send this token to</p>
              <Input
                type="text"
                name="clientEmail"
                label="Account Contact Email"
                value={this.state.clientEmail}
                onChange={this.handleChange}
              />
            </>
            :
            <>
              <p>Token is: <span id="generatedToken">{this.state.generatedToken}</span>
                <Button
                  onClick={() => { this.copyToken(this.state.generatedToken) }}
                  icon={<CopyOutlined />}
                />
              </p>
              <p>Full Url: <a href={`${window.location.origin}/?token=${this.state.generatedToken}`}>
                <span id="generatedUrl">{`${window.location.origin}?token=${this.state.generatedToken}`}</span>
              </a>
                <Button
                  onClick={() => { this.copyToken(`${window.location.origin}/?token=${this.state.generatedToken}`) }}
                  icon={<CopyOutlined />}
                />
              </p>
            </>
          }
        </Modal>
        <div className="table-top-bar">
          <div className="adminsearchinput">
            <Input
              prefix={<FontAwesomeIcon className="adminsvg" icon={faSearch} />}
              placeholder="Search for Submission.."
              name="searchValue"
              onChange={this.changeSearchValue}
            />
          </div>
          <button
            className="request"
            onClick={() => this.setState({ ...this.state, isSendTokenPopupOpen: !this.state.isSendTokenPopupOpen })}
          >
            Create Token
          </button>
        </div>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ defaultPageSize: 15, showSizeChanger: true, pageSizeOptions: ['10', '15', '30'] }}
        />
      </div>
    )
  }
}

export default Admin;
