import axios from 'axios'

/**
 * Get the ID Of a submission
 * 
 * @param {object} tokenData The token data to validate
 * @returns Success response or false
 */
export const getSubmissionID = async (tokenData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/submission`, tokenData);
    return response.data;
  } catch (e) {
    return false;
  }
}

/**
 * Update a submission
 * 
 * @param {string} submissionId The ID of the submission
 * @param {object} data The new data of the submission
 * @param {string} token The token to validate
 * @returns Success response or false
 */
export const updateSubmission = async (submissionId, data, token) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/submission/${submissionId}`, {
      data,
      token
    });
    return response.data;
  } catch (e) {
    return false;
  }
}

/**
 * Validate that a request to change data of the submission is properly linked
 * 
 * @param {string} submissionID The submission ID
 * @param {string} updateID The ID of the update request
 * @returns Success response or false
 */
export const verifyUpdateRequestID = async (submissionID, updateID) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/submission/verifyUpdateID`, {
      submissionID,
      updateID
    });
    return response.data;
  } catch (e) {
    return false;
  }
}
