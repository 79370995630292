import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { handleFileDownload } from '../../api/fileAPI';
import { useParams } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';

const StageFourteen = (props) => {

  const handleSubmit = (values) => {
    props.handleNext(values);
  };

  const validationSchema = Yup.object({
    terms: Yup.boolean().default(false).oneOf([true], 'This is required'),
    privacy: Yup.boolean().default(false).oneOf([true], 'This is required'),
  });

  // This is called when a user wants to download the requested files for that particular submission.
  // Response from server is a blob and need to create a ObjectURL from that blob.
  function fetchSubmissionFile(filename) {
    handleFileDownload(filename)
        .then((data) => {
            if (data.type === 'application/pdf') {
                const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
                const pdfWindow = window.open('/');
                pdfWindow.location.href = url;
            } else {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        }).catch((err) => console.log(err))
  }

  return (
    <Formik
      initialValues={props.formData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form>
          <div className="step">
            <h2>Terms and Conditions</h2>
            <div className="terms stepform">
              <p>Please ensure you have downloaded and read the Terms & Conditions and Privacy Policy</p>
              <div>
                <button className="formDownloadButton" onClick={() => fetchSubmissionFile('icm_terms_and_conditions.pdf')}>Terms & Conditions</button>
                <button className="formDownloadButton" onClick={() => fetchSubmissionFile('icm_privacy_policy.pdf')}>Privacy Policy</button>
              </div>
              <div className="terms-box">
                <label>
                  <Field type="checkbox" name="terms" className="terms-checkbox" />
                  I have read and I agree with the Terms & Conditions
                  <ErrorMessage component="div" name="terms" className="errormsg"/>
                </label>
                <label>
                  <Field type="checkbox" name="privacy" className="terms-checkbox" />
                  I have read and I understand the Privacy Policy
                  <ErrorMessage component="div" name="privacy" className="errormsg"/>
                </label>
              </div>
            </div>
            <button className="stepbutton" type="submit">Submit</button>
          </div>

        </Form>
      )
      }
    </Formik>
  )
}

export default StageFourteen;
