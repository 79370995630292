import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox, faSignOutAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link, withRouter } from 'react-router-dom';
import './Navbar.scss';
import { logout } from '../slices/userSlice';
import { connect } from 'react-redux';

import { ReactComponent as Logo } from '../images/logo.svg'

class Navbar extends Component {


  getRouteClass(path) {
    return this.props.location.pathname.includes(path) ? 'navbarLinksSelected' : '';
  }

  render() {
    return <div className="dashboard">
      <div className="navbar">
        <Logo className="icmlogoNav" />
        <div className="NavbarItems">
          <div className="NavbarSection">
            <h2>Main</h2>
            <Link to="/admin" className={this.getRouteClass('/admin')}>
              <span>
                <FontAwesomeIcon
                  className={"navbarSvg"}
                  icon={faInbox}
                />
                Submissions
              </span>
            </Link>
            {this.props.user.role === 'admin' &&
              <Link to="/users" className={this.getRouteClass('/users')}>
                <span>
                  <FontAwesomeIcon
                    className={"navbarSvg"}
                    icon={faUsers}
                  />
                  Manage Users
                </span>
              </Link>
            }
          </div>
          <div className="NavbarSection">
            <a onClick={this.props.logout}>
              <span>
                <FontAwesomeIcon
                  className={"navbarSvg"}
                  icon={faSignOutAlt}
                />
                Logout
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="navbarTop">
          <h1>{this.props.title}</h1>
        </div>
        {this.props.children}
      </div>
    </div>
  }
}

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
