import React, { Component } from 'react';
import './Users.scss'
import { Table, Space, Input, Modal, Popconfirm, Button, Select } from 'antd';
import { fetchUsers, addNewUser, removeUser, updateUser } from '../api/adminAPI';

const { Option } = Select;

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isModalOpen: false,
      userFirstName: '',
      userLastName: '',
      userEmail: '',
      userRole: '',
      modalErr: '',
    }

    this.toggleModal = this.toggleModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  componentDidMount() {
    this.getUsers();
  }

  /**
   * Get all the users
   */
  getUsers() {
    fetchUsers()
      .then(data => {
        this.setState({ ...this.state, users: data.users })
      }).catch((err) => console.log(err));
  }

  /**
   * Delete a user
   * 
   * @param {string} id The id of the user to delete
   */
  deleteUser(id) {
    removeUser(id)
      .then(
        this.getUsers()
      ).catch((err) => console.log(err));
  }

  /**
   * Toggle the user creation modal and wipe all the data
   */
  toggleModal() {
    this.setState({
      ...this.state,
      isModalOpen: !this.state.isModalOpen,
      userFirstName: '',
      userLastName: '',
      userEmail: '',
      modalErr: ''
    });
  }

/**
 * Handle an on change event for field input
 * 
 * @param {DOMEvent | string} e The onchange event of a field
 */
  handleChange(e) {
    if (typeof (e) == 'string') {
      // Select pulls through just the string value
      this.setState({ ...this.state, ['userRole']: e });
    } else {
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
  }

  /**
   * Submit a new user after checking all details are correct
   */
  handleOk() {

    if (this.state.userFirstName === '') {
      this.setState({ ...this.state, modalErr: 'Please enter a valid first name.' });
    }

    else if (this.state.userLastName === '') {
      this.setState({ ...this.state, modalErr: 'Please enter a valid last name.' });
    }

    else if (this.state.userEmail === '') {
      this.setState({ ...this.state, modalErr: 'Please enter a valid email address.' });
    }

    else if (! this.state.modalErr) {
      this.toggleModal();

      addNewUser(this.state.userFirstName, this.state.userLastName, this.state.userEmail, this.state.userRole)
        .then(
          this.getUsers()
        ).catch((err) => console.log(err));
    }
  }

  /**
   * Handle the role of the user changing by updating the user
   * 
   * @param {string} new_role The new role of the user
   * @param {string} text The text of the new role
   * @param {string} record The current record of the user
   */
  handleUserRoleChange(new_role, text, record) {
    updateUser(record.firstname, record.lastname, record.email, new_role);
  }

  render() {
    const columns = [
      {
        title: 'First Name',
        dataIndex: 'firstname',
        key: 'firstname',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastname',
        key: 'lastname',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Role',
        dataIndex: 'user_role',
        key: 'user_role',
        render: (text, record) => (
          <Select
            defaultValue="Select Role"
            value={record.user_role}
            style={{ width: "100%" }}
            onChange={(e) => this.handleUserRoleChange(e, text, record)}
          >
            <Option value="guest">Guest</Option>
            <Option value="admin">Admin</Option>
          </Select>
        )
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <Popconfirm
              title="Are you sure to delete this user?"
              onConfirm={() => this.deleteUser(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <button className="deluser">Delete User</button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <div className="users">
        <Button className="newuserbutton" type="primary" onClick={this.toggleModal}>Add User</Button>
        <Modal title="Add User" visible={this.state.isModalOpen} onOk={this.handleOk} onCancel={this.toggleModal}>
          <div className="modalinput">
            <h3>First Name</h3>
            <Input
              placeholder="John"
              value={this.state.userFirstName}
              onChange={this.handleChange}
              name="userFirstName"
            />
          </div>

          <div className="modalinput">
            <h3>Last Name</h3>
            <Input
              placeholder="Smith"
              value={this.state.userLastName}
              onChange={this.handleChange}
              name="userLastName"
            />
          </div>

          <div className="modalinput">
            <h3>Email Address</h3>
            <Input
              placeholder="user@icmco.com.au"
              value={this.state.userEmail}
              onChange={this.handleChange}
              name="userEmail"
            />
          </div>

          <div className="modalinput">
            <h3>User Role</h3>
            <Select
              defaultValue="Select Role"
              style={{ width: "100%" }}
              value={this.state.userRole}
              onChange={this.handleChange}
              name="userRole"
            >
              <Option value="guest">Guest</Option>
              <Option value="admin">Admin</Option>
            </Select>
          </div>

          <p className="modalerror">{this.state.modalErr}</p>

        </Modal>
        <div className="usertable">
          <Table
            dataSource={this.state.users}
            columns={columns}
            pagination={{ defaultPageSize: 30 }}
           />
        </div>
      </div>

    )
  }
}

export default Users;
