import { createSlice } from '@reduxjs/toolkit'
import { checkAuth, logoutUser } from '../api/authAPI';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    authenticated: false,
    loginPending: true,
    loginError: false
  },
  reducers: {
    loginUserStart: (state) => {
      state.loginPending = true;
      state.loginError = false;
    },
    loginUserSuccess: (state, { payload }) => {
      // The user has logged in.
      state.role = payload;
      state.loginPending = false;
      state.authenticated = true;
    },
    loginUserFailure: (state) => {
      // Failed to login... invalid username or password.
      state.loginPending = false;
      state.authenticated = false;
      state.loginError = true;
    },
    signupUserStart: (state) => {
      state.signupPending = true;
    },
    logoutUserSuccess: (state) => {
      state.authenticated = false;

      window.location.reload();
    }
  },
})

// Action creators are generated for each case reducer function
export const { loginUserStart, loginUserSuccess, loginUserFailure, logoutUserSuccess } = userSlice.actions

export default userSlice.reducer

export function loggedInCheck() {
  return async (dispatch) => {
      dispatch(loginUserStart());
      const alreadySignnedIn = await checkAuth();
      if (alreadySignnedIn.authenticated) return dispatch(loginUserSuccess(alreadySignnedIn.role));
      dispatch(loginUserFailure());
  }
}

export function logout() {
    return async (dispatch) => {
      try {
       // await logoutUser();
        await logoutUser();
        dispatch(logoutUserSuccess());
      } catch (error) {
        dispatch(logoutUserSuccess());
      }
    }
  }
