import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Home.scss';
import { ReactComponent as Logo } from '../images/logo.svg'
import { getSubmissionID } from "../api/submissionAPI";
import { Table, Space, Input, Modal, Popconfirm, Button, Select } from 'antd';

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      secureToken: new URLSearchParams(this?.props?.location?.search).get('token'),
      tokenError: new URLSearchParams(this?.props?.location?.search).get('err'),
    }
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * Handle an on change event for field input
   * 
   * @param {DOMEvent | string} e The onchange event of a field
   */
  handleChange = async (e) => {
    if (typeof (e) == 'string') {
      // Select pulls through just the string value
      this.setState({ ...this.state, ['userRole']: e });
    } else {
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
  }

  /**
   * Handle a click event to the next page
   */
  handleClick = async () => {
    const data = await getSubmissionID({ token: this.state.secureToken });

    this.props.history.push(`/form/${data.submissionId}?token=${data.token}`);
  }

  render() {

    return <div className="Home">
      <Logo className="icmlogo" />

      <div className="homescreen">
        <h2 className="homeheader">Creditor Portal</h2>
        <p className="hometext">You can use this portal to submit all of your company and licence information.</p>
        <p className="home-list">
          To complete your application, ICM will ask for the following information to be provided:
          <ul>
            <li>Your ABN and ACN</li>
            <li>Your Business Details</li>
            <li>Your Business Contact Details</li>
            <li>Your Insurance & Compliance Details</li>
            <li>Upload Copies of Insurances & Compliances</li>
            <li>Accept Terms of Trade</li>
            <li>Accept Privacy Policy</li>
          </ul>
        </p>
        <Input placeholder="token" value={this.state.secureToken} onChange={this.handleChange} name="secureToken" />
        {this.state.tokenError &&
          <p className="error-text">There was an error with the token, please try again or contact <a href="mailto:accounts@icmco.com.au" target="_blank">accounts@icmco.com.au</a></p>
        }
        <button className="homebutton" type="button" onClick={this.handleClick}>Get Started</button>
      </div>
    </div>
  }
}

export default withRouter(Home);
