import axios from 'axios';

/**
 * Fetch all of the submissions
 * 
 * @returns The submissions or false
 */
export const fetchSubmissions = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/getSubmissions`, {});
    return response.data.submissions;
  } catch (e) {
    return false;
  }
}

/**
 * Fetch a particular submission
 * 
 * @param {string} submissionID The id of the submission to fetch
 * @returns The submission or false
 */
export const fetchSubmission = async (submissionID) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/getSubmission`, {
      submissionID: submissionID
    });
    return response.data;
  } catch (e) {
    return false;
  }
}

/**
 * Approve of a pending submission
 * 
 * @param {object} data The data to submit with the submission
 * @param {string} jonasVendorCode The vendor code from Jonas
 * @param {string} companyCode The company code (from Jonas)
 * @param {string} purchaseCategory Purchase category in Jonas
 * @returns Response data or false
 */
export const approveSubmission = async (data, jonasVendorCode, companyCode, purchaseCategory) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/approveSubmission`, {
      data: data,
      jonasVendorCode: jonasVendorCode,
      companyCode: companyCode,
      purchaseCategory: purchaseCategory
    });
    return response.data;
  } catch (e) {
    return false;
  }
}

/**
 * Delete a submission
 * 
 * @param {string} submissionId The id of the submission
 * @returns Success response or false
 */
export const deleteSubmission = async (submissionId) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/deleteSubmission`, {
      submissionId
    });
    return response.data;
  } catch (e) {
    return false;
  }
}

/**
 * Send a request to update the submission
 * 
 * @param {string} submissionID The id of the submission
 * @param {string} reason The reason requested for the change
 * @returns Success response or false
 */
export const requestSubmissionUpdate = async (submissionID, reason) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/requestSubmissionUpdate`, {
      submissionID: submissionID,
      reason: reason
    });
    return response.data;
  } catch (e) {
    return false;
  }
}

/**
 * Send a token generation request to the server
 * 
 * @param {string} email The optional email to send the token to
 * @returns Success response or false
 */
export const generateToken = async (email) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/generateToken`, {
      email
    });
    return response.data;
  } catch (e) {
    return false;
  }
}

/**
 * Check whether the token is valid
 * 
 * @param {object} tokenData The token to validate against
 * @returns Success response or false
 */
export const checkToken = async (tokenData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/checkToken`, tokenData);
    return response.data;
  } catch (e) {
    return false;
  }
}

/**
 * Fetch all the users
 * 
 * @returns Success response or false
 */
export const fetchUsers = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/fetchUsers`, {});
    return response.data;
  } catch {
    return false;
  }
}

/**
 * Create a new user
 * 
 * @param {string} firstName The firstname of the user
 * @param {string} lastName The lastname of the user
 * @param {string} email The email of the user
 * @param {string} role The role of the user
 * @returns Success response or false
 */
export const addNewUser = async (firstName, lastName, email, role) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/addNewUser`, {
      firstName,
      lastName,
      email,
      role
    });
    return response.data;
  } catch {
    return false;
  }
}

/**
 * Update a user
 * 
 * @param {string} firstName The firstname of the user
 * @param {string} lastName The lastname of the user
 * @param {string} email The email of the user
 * @param {string} role The role of the user
 * @returns Success response or false
 */
export const updateUser = async (firstName, lastName, email, role) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/updateUser`, {
      firstName,
      lastName,
      email,
      role
    });
    return response.data;
  } catch {
    return false;
  }
}

/**
 * Delete a user from the platform
 * 
 * @param {string} userID The ID of the user to delete
 * @returns Success response or false
 */
export const removeUser = async (userID) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/removeUser`, {
      userID
    });
    return response.data;
  } catch {
    return false;
  }
}
